const Bag = () => {
  return (
    <div className="overflow-hidden border rounded-md bg-bag h-96">
      <div className="bg-gradient-to-t from-[#127EC8] to-[#127EC880] h-full relative">
        <div className="absolute bottom-0 grid gap-5 p-8 text-white">
          <span className="font-bold text-center md:text-left">
            Zippy Invoice
          </span>
          <span className="text-center md:text-left">
            An innovative invoice app designed to streamline and simplify your
            billing process. With intuitive features, it caters to both
            financial and non-financial needs, offering a comprehensive solution
            for managing invoices efficiently.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Bag;
