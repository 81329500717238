import React, { useEffect, useState } from "react";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Smallmenu from "./smallScreenMenu";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  const showMenu = () => {
    setActive(!active);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hideHeader = location.pathname === "/connect";

  if (hideHeader) {
    return null;
  }

  const currentPage = "py-6 px-5 bg-white/10 flex gap-4 items-center";
  const otherPage = "py-6 px-5 flex gap-2 items-center";

  return (
    <div>
      <div
        className={`${
          isSticky ? "sticky" : ""
        } flex items-center justify-between px-4 bg-[#f4f4f4] md:px-16 border border-b-gray-300
        transition-all duration-300 relative z-[1000]`}
        id="navbar"
      >
        <div>
          <a href="/" className="flex items-center gap-3">
            <img src={logo} className="h-5" alt="Macrotel Logo" />
            <span className="text-xl font-bold text-[#111217]">Macrotel.</span>
          </a>
        </div>
        <div>
          <ul className="hidden text-sm text-[#111217] lg:flex">
            <li>
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive ? currentPage : otherPage
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M19 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-9H0l10.327-9.388a1 1 0 0 1 1.346 0L22 11h-3v9zm-8-5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                    fill="rgba(17, 18, 23, 1)"
                  />
                </svg>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/about"}
                className={({ isActive }) =>
                  isActive ? currentPage : otherPage
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z"
                    fill="rgba(17, 18, 23, 1)"
                  />
                </svg>
                About us
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/products"}
                className={({ isActive }) =>
                  isActive ? currentPage : otherPage
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M20 13v7a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16zM14.5 2a3.5 3.5 0 1 1 3.163 5.001L21 7a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1l3.337.001a3.5 3.5 0 0 1 5.664-3.95A3.48 3.48 0 0 1 14.5 2zm-5 2a1.5 1.5 0 0 0-.144 2.993L9.5 7H11V5.5a1.5 1.5 0 0 0-1.356-1.493L9.5 4zm5 0l-.144.007a1.5 1.5 0 0 0-1.35 1.349L13 5.5V7h1.5l.144-.007a1.5 1.5 0 0 0 0-2.986L14.5 4z"
                    fill="rgba(17, 18, 23, 1)"
                  />
                </svg>
                Products
              </NavLink>
            </li>

            <li>
              <NavLink
                to={"/gallery"}
                className={({ isActive }) =>
                  isActive ? currentPage : otherPage
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="16"
                  height="16"
                  // class="w"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/connect"}
                className={({ isActive }) =>
                  isActive ? currentPage : otherPage
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M21 8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1.062A8.001 8.001 0 0 1 12 23v-2a6 6 0 0 0 6-6V9A6 6 0 1 0 6 9v7H3a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1.062a8.001 8.001 0 0 1 15.876 0H21zM7.76 15.785l1.06-1.696A5.972 5.972 0 0 0 12 15a5.972 5.972 0 0 0 3.18-.911l1.06 1.696A7.963 7.963 0 0 1 12 17a7.963 7.963 0 0 1-4.24-1.215z"
                    fill="rgba(17, 18, 23, 1)"
                  />
                </svg>
                Connect with us
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          onClick={showMenu}
          className="py-6 space-y-1 HAMBURGER-ICON lg:hidden"
        >
          <span className="block h-0.5 w-4 bg-[#111217]"></span>
          <span className="block h-0.5 w-4 bg-[#111217]"></span>
          <span className="block h-0.5 w-4 bg-[#111217]"></span>
        </div>
      </div>
      <Smallmenu showMenu={showMenu} active={active} />
    </div>
  );
};

export default Navbar;
