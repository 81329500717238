const Zippyworld = () => {
  return (
    <div className="overflow-hidden border rounded-md bg-mesh h-96">
      <div className="h-full background">
        <div className="bg-gradient-to-t from-[#127EC8] to-[#127EC880] h-full relative">
          <div className="absolute bottom-0 grid gap-5 p-8 text-white">
            <span className="font-bold text-center md:text-left">
              Zippyworld
            </span>
            <span className="text-center md:text-left">
              A Lifestyle app that can be used for all forms of financial and
              non-financial services
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zippyworld;

// Our Product

// 2. USSD Pension:
// CPS and MPS customers of ARM can now interact with their pension account using *597#

// 3. USSD Insurance:
// Nigeria can now buy general insurance such as third-party Insurance, comprehensive Insurance, and family insurance such as travel insurance, savings and so much more with Allianz on *1016# or STI on *1015#

// 4. USSD Investment
// You can invest with ARM Investment as low as N5,000 on varieties of investment on *597#
