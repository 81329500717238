import Team from "../Components/team";
import Testimonial from "./Landing/testimonial";
import Features from "./Landing/features";
import Navbar from "../Components/navBar";
import group from "../Images/group.jpg";
import { motion } from "framer-motion";

const AboutUs = () => {
  document.title = "About us";

  return (
    <div>
      <Navbar />
      <div className="grid gap-10 pt-5 mb-10 bg-[#f4f4f4]">
        <div>
          <div className="grid justify-items-center bg-[#f4f4f4]">
            <motion.h2
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
              className="w-full md:w-1/2 text-center text-[20px] font-bold bg-clip-text mb-5"
            >
              {/* Committed to delivering neutral solutions to our partners */}
              Who we are
            </motion.h2>
          </div>
          {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "spring",
              duration: 0.5,
              stiffness: 120,
              delay: 0.5,
            }}
            className="flex justify-between gap-5 mx-4 overflow-x-scroll md:mx-16"
          >
            <img
              src={pic1}
              alt=""
              className="object-cover border rounded-md h-80 w-96"
            />
            <img
              src={pic2}
              alt=""
              className="object-cover border rounded-md h-80 w-96"
            />
            <img
              src={pic3}
              alt=""
              className="object-cover border rounded-md h-80 w-96"
            />
          </motion.div> */}
        </div>
        <Features />
        <div
          className="flex flex-col items-center gap-4 px-4 py-8 bg-white md:flex-row md:py-20 md:px-20 md:gap-12"
          id="about"
        >
          <div className="w-full md:w-1/2">
            <img src={group} alt="Group image" />
          </div>

          <div className="w-full md:w-1/2">
            <h1 className="mb-4 text-xl font-bold lg:text-6xl">
              <span className="text-9xl">o</span>ur vision and mission
            </h1>

            <p className="leading-8">
              To be the most preferred financial technology company in sub
              Sahara Africa.
              <br />
              We are always a high-quality financial technology providers with
              the urge to the best at all times through the delivery of customer
              centric platform, innovative solutions and creating role models
              for the society.
            </p>
          </div>
        </div>
        <Team />
        <Testimonial />
      </div>
    </div>
  );
};

export default AboutUs;
