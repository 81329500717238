import React, { useState, useEffect } from "react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const Testimonial = () => {
  const testimonials = [
    {
      name: "Goke Aruna",
      content:
        "Fantastic App. I used it to buy my airtime and I used it for my electricity bills",
    },
    {
      name: "Yahaya Mustapha",
      content:
        "Excellent app, smooth and very efficient to perform all transactions and bill payments. I love this.",
    },
    {
      name: "Owolabi Omotayo Isaiah",
      content: "An excellent, reliable, dependable and useful app.",
    },
    {
      name: "Ademola Orolu",
      content:
        "Alternative to all the bank apps. Better with low or no fee or commission.",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentTestimonial, testimonials.length]);

  const nextTestimonial = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      (currentTestimonial - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="relative">
      <button
        onClick={prevTestimonial}
        className="absolute left-0 p-2 ml-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
      >
        <MdArrowBackIos />
      </button>
      <button
        onClick={nextTestimonial}
        className="absolute right-0 p-2 mr-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
      >
        <MdArrowForwardIos />
      </button>

      <section className="py-16 bg-gray-100">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">
              Testimonials
            </h2>
            <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              What our customers are saying
            </p>
          </div>

          <div className="mt-20">
            <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg">
              <div className="px-6 py-4">
                <p className="mb-4 text-lg font-semibold text-gray-900">
                  "{testimonials[currentTestimonial].content}"
                </p>
                <p className="text-sm text-gray-700">
                  {testimonials[currentTestimonial].name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
