const Pos2 = () => {
  return (
    <div className="overflow-hidden border rounded-md bg-pos2 h-96">
      <div className="bg-gradient-to-t from-[#127EC8] to-[#127EC880] h-full relative">
        <div className="absolute bottom-0 grid gap-5 p-8 text-white">
          <span className="font-bold text-center md:text-left">
            Analogue POS
          </span>
          <span className="text-center md:text-left">
            Businesses require a Point of Sale (POS) system in order to handle
            transactions and manage sales effectively.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Pos2;
