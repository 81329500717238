import HeroSection from "./heroSection";
import Ctx from "./ctx";
import Features from "./features";
import Testimonial from "./testimonial";

// import Navbar from '../../Components/navBar';

const LandingPage = () => {
  document.title = "Home";

  const testimonials = [
    {
      quote:
        "Fantastic App. I used it to buy my airtime and I used it for my electricity bills.",
      author: "Goke Aruna",
    },
    {
      quote:
        "Excellent app, smooth and very efficient to perform all transactions and bill payment. I love this.",
      author: "Yahaya Mustapha",
    },
    {
      quote: "An excellent, reliable, dependable and useful app.",
      author: "Owolabi Omotayo Isaiah",
    },
    {
      quote:
        "Alternative to all the banks app. Better with low or no fee or commission.",
      author: "Ademola Orolu",
    },
  ];

  return (
    <div>
      <HeroSection />
      <div className="grid gap-20">
        <Ctx />
        <Features />
        <Testimonial />
      </div>
    </div>
  );
};

export default LandingPage;
