import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaFacebookF } from "react-icons/fa6";

const Footer = () => {
  const location = useLocation();
  const hideFooter = location.pathname === "/connect";
  if (hideFooter) {
    return null;
  }

  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-white bg-gray-800">
      <div className="grid max-w-6xl gap-8 px-4 py-8 mx-auto md:grid-cols-12 sm:px-6 lg:px-8">
        <div className="col-span-4">
          <h3 className="mb-3 text-lg font-bold">Macrotel Innovations</h3>
          <p className="mb-2">
            To be the leading provider of software solutions
          </p>
        </div>
        <div className="col-span-5">
          <h3 className="mb-3 text-lg font-bold">Contact Us</h3>
          <p className="mb-4">
            Head Office: <br />
            <span className="text-sm font-light">
              2, Dele Omodara close, Omole-Phase 1, Ikeja, Lagos, Nigeria.
            </span>
          </p>
          <p className="mb-4">
            Phone:{" "}
            <span className="text-sm font-light">08099440151, 09039855986</span>
            <br />
            9am - 5pm Monday to Friday.
          </p>
          <p>Email: info@macrotelgroup.com</p>
        </div>
        <div className="col-span-3">
          <h3 className="mb-3 text-lg font-bold">Follow Us</h3>
          <div className="flex mt-2 space-x-4 text-white">
            {/* Facebook Link  */}
            <Link
              to="https://www.facebook.com/zippyworld1?mibextid=LQQJ4d"
              target="_blank"
            >
              <FaFacebookF size={18} />
            </Link>

            {/* Instagram Link  */}
            <Link
              to="https://www.instagram.com/zippyworldnet?igsh=bmR6NnR0cXE3anJk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={18} />
            </Link>

            {/* Twitter Link  */}
            <Link
              to="https://x.com/zippyworld_app?s=11&t=-pMpQQkAx4cNTWlHNo11iA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter size={18} />
            </Link>
          </div>
        </div>
      </div>
      <div className="py-4 text-center bg-gray-900">
        <p>&copy; {currentYear} Macrotel. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
