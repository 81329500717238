import { motion } from "framer-motion";
import Navbar from "../Components/navBar";
import image1 from "../Images/gallery1.png";
import image2 from "../Images/gallery2.png";
import image3 from "../Images/gallery3.png";
import image4 from "../Images/gallery4.png";
import image5 from "../Images/gallery5.png";
import image6 from "../Images/gallery6.png";

const images = [image1, image2, image3, image4, image5, image6];

const Gallery = () => {
  document.title = "Gallery";

  return (
    <div>
      <Navbar />
      <div className="container p-8 mx-auto">
        <div>
          <div className="grid justify-items-center bg-[#f4f4f4]">
            <motion.h2
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
              className="w-full md:w-1/2 text-center text-[20px] font-bold bg-clip-text mb-5"
            >
              Gallery
            </motion.h2>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {images.map((image, index) => (
            <div key={index} className="overflow-hidden rounded-lg shadow-md">
              <img src={image} alt="" className="w-full" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
