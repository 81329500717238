import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaFacebookF } from "react-icons/fa6";

const ConnectForm = () => {
  const currentYear = new Date().getFullYear();

  const TextBox =
    "w-full border p-4 text-xs focus:outline-none focus:border-gray-400 rounded";

  return (
    <div className="grid content-between h-screen">
      <div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            duration: 0.5,
            stiffness: 120,
            delay: 1,
          }}
          className="p-8 m-2 bg-white border rounded-md"
        >
          <p className="mb-8 text-[#00000040]">Send Us A Message</p>
          <form>
            <div className="grid grid-cols-1 gap-5 mb-6 md:grid-cols-2">
              <label>
                Your name: <br />
                <input type="text" className={TextBox} />
              </label>
              <label>
                Your email: <br />
                <input type="text" className={TextBox} />
              </label>
              <label className=" md:col-span-2">
                Your message: <br />
                <textarea
                  placeholder="Start typing..."
                  className={TextBox}
                ></textarea>
              </label>
            </div>

            <button
              type="submit"
              className="px-[64px] py-[16px] bg-gray-800 text-white text-sm rounded flex items-center gap-3"
            >
              <span>Send message</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M3 13h6v-2H3V1.846a.5.5 0 0 1 .741-.438l18.462 10.154a.5.5 0 0 1 0 .876L3.741 22.592A.5.5 0 0 1 3 22.154V13z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </button>
          </form>
        </motion.div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
          className="flex items-center gap-5 p-8 m-2 bg-white border rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z"
              fill="rgba(37,211,102,1)"
            />
          </svg>
          <span className="text-sm">
            You can call or whatsapp us on 08012345679
          </span>
        </motion.div>
      </div>
      {/* <ContactFooter /> */}
      <div className="py-10 text-center bg-white border-t md:fixed md:bottom-0 md:w-full">
        <div className="px-5">
          <div className="flex justify-between text-xs font-bold">
            <div>Macrotel Innovations Limited © {currentYear}</div>
            <div className="flex gap-3">
              <span className="opacity-50 hover:opacity-100">
                <Link
                  to="https://www.facebook.com/zippyworld1?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={14} />
                </Link>
              </span>

              <span className="opacity-50 hover:opacity-100">
                {/* Instagram Link  */}
                <Link
                  to="https://www.instagram.com/zippyworldnet?igsh=bmR6NnR0cXE3anJk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={14} />
                </Link>
              </span>

              <span className="opacity-50 hover:opacity-100">
                {/* Twitter Link  */}
                <Link
                  to="https://x.com/zippyworld_app?s=11&t=-pMpQQkAx4cNTWlHNo11iA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter size={14} />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectForm;
