import ProfileCard from "./TeamProfileCard";
import Muhideen from "../Images/Muihdeen Ashiru.png";
import Funke from "../Images/Adefunke Adekomaiya.png";
import Hammed from "../Images/Hammed Adigun.png";
import Bode from "../Images/Orolu Olabode.png";
import Ayodele from "../Images/Ayodele Adedugba.jpg";

const Team = () => {
  return (
    <div className="px-4 md:px-16">
      <p className="mb-4 text-center">Meet The Team</p>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-5">
        <ProfileCard
          profile={Bode}
          firstName="Olabode"
          lastName="Orolu"
          role="CEO"
        />
        <ProfileCard
          profile={Muhideen}
          firstName="Muhideen"
          lastName="Ashiru"
          role="Operations Manager"
        />
        <ProfileCard
          profile={Hammed}
          firstName="Hammed"
          lastName="Adigun"
          role="System and Integration Officer"
        />
        <ProfileCard
          profile={Funke}
          firstName="Adekomaiya"
          lastName="Adefunke"
          role="Finance/Admin Manager"
        />
        <ProfileCard
          profile={Ayodele}
          firstName="Ayodele"
          lastName="Adedugba"
          role="Head Customer Experience"
        />
      </div>
    </div>
  );
};

export default Team;
