import Back from "../Images/back arrow.svg";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";

const ConnectSide = () => {
  const navigate = useNavigate();

  return (
    <div className="h-[100px] md:h-screen p-2 md:p-0">
      <div className="grid min-h-screen text-white bg-gray-800 rounded md:rounded-none">
        <img
          src={Back}
          alt="Back"
          className="p-5 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <motion.p
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            duration: 0.5,
            stiffness: 120,
            delay: 2,
          }}
          className="hidden md:block text-center p-3 text-[14px] leading-7"
        >
          “Innovation is the unrelenting drive to break the status quo and
          develop anew where few have dared to go.“
          <br />
          <span className="opacity-50">
            - <br /> Stephen Jeffes
          </span>
        </motion.p>
        <div className="flex items-center justify-center">
          <div className="w-[230px] bg-[#FFE6F5] text-[#800450] rounded-2xl py-4 px-5 mb-6">
            {" "}
            {/* Added mx-auto and max-w-sm */}
            <div className="flex items-center justify-between mb-3">
              <h3 className="font-bold">Instagram</h3>
              <RiInstagramFill />
            </div>
            <p className="mb-4 text-xs">
              Stay connected with us! Follow us on social media for the latest
              updates, news, and exclusive content. Join our community today
            </p>
            <div className="flex items-center justify-between text-xs font-bold">
              <p>For more updates</p>
              <div>
                <Link
                  to="https://www.instagram.com/zippyworldnet?igsh=bmR6NnR0cXE3anJk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Follow us
                </Link>
                <MdKeyboardArrowRight className="inline" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className="w-[230px] bg-[#CEF0FF] text-[#3D4178] rounded-2xl py-4 px-5 mb-6">
            {" "}
            {/* Added mx-auto and max-w-sm */}
            <div className="flex items-center justify-between mb-3">
              <h3 className="font-bold">Facebook</h3>
              {/* <RiInstagramFill /> */}
              <FaFacebook />
            </div>
            <p className="mb-4 text-xs">
              Stay connected with us! Follow us on social media for the latest
              updates, news, and exclusive content. Join our community today
            </p>
            <div className="flex items-center justify-between text-xs font-bold">
              <p>For more updates</p>
              <div>
                <Link
                  to="https://www.facebook.com/zippyworld1?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Follow us
                </Link>
                <MdKeyboardArrowRight className="inline" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className="w-[230px] bg-[#DCFFCE] text-[#3D6528] rounded-2xl py-4 px-5 mb-6">
            {" "}
            {/* Added mx-auto and max-w-sm */}
            <div className="flex items-center justify-between mb-3">
              <h3 className="font-bold">WhatsApp</h3>
              <RiWhatsappFill />
              {/* <RiInstagramFill /> */}
            </div>
            <p className="mb-4 text-xs">
              Stay connected with us! Follow us on social media for the latest
              updates, news, and exclusive content. Join our community today
            </p>
            <div className="flex items-center justify-between text-xs font-bold">
              <p>For more updates</p>
              <div>
                <Link
                  to="https://whatsapp.com/channel/0029Va9mUMe9RZAdx2JndP1a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Follow us
                </Link>
                <MdKeyboardArrowRight className="inline" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectSide;
