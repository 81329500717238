import React, { useState, useEffect } from "react";
import Navbar from "../../Components/navBar";
import { Link } from "react-router-dom";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const HeroSection = () => {
  const heroSections = [
    {
      title: "We Share Creative Solutions",
      content:
        " Providing high-quality and cost-effective fintech solutions, our firm is dedicated to offering partner neutral solutions that incorporate industry best practices with the latest technology. We address customer needs through the digitalization of products and services.",
      buttonText: "Get in touch",
      buttonLink: "/connect",
      backgroundClass: "slide1",
      clientCount: 50,
      activeTransactionsCount: 100,
    },
    {
      title: "Collaborative Solutions, Creative Minds.",
      content:
        "At our core, we're dedicated to sharing creative solutions that drive success. With a focus on collaboration and innovation, we're committed to delivering cutting-edge solutions that meet the evolving needs of our partners. Join us on this journey of creativity and discovery, as we pave the way for a brighter, more innovative future.",
      buttonText: "Connect with us",
      buttonLink: "/connect",
      backgroundClass: "slide2",
      clientCount: 50,
      activeTransactionsCount: 100,
    },
    {
      title: "Empowering Innovations in Fintech",
      content:
        "Our company specializes in delivering top-notch and affordable fintech solutions. Committed to providing unbiased solutions, we integrate industry-leading practices with cutting-edge technology. Our focus on digitalization ensures we meet the diverse needs of our clients by transforming products and services into digital assets",
      buttonText: "Learn more",
      buttonLink: "/about",
      backgroundClass: "slide3",
      clientCount: 50,
      activeTransactionsCount: 100,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [counted, setCounted] = useState(false);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % heroSections.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (currentSlide - 1 + heroSections.length) % heroSections.length
    );
  };

  const countUp = (targetElement, start, end, duration) => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));
    const timer = setInterval(() => {
      current += increment;
      targetElement.textContent = current;
      if (
        (increment > 0 && current >= end) ||
        (increment < 0 && current <= end)
      ) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".md\\:col-span-5");
      const rect = element.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isVisible && !counted) {
        setCounted(true);
        startCounting();
      } else if (!isVisible) {
        setCounted(false);
      }
    };

    handleScroll(); // Trigger counting on page load
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [counted]); // Add counted to dependencies array

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % heroSections.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentSlide, heroSections.length]);

  const startCounting = () => {
    const clientCount = document.getElementById("client-count");
    const activeTransactionsCount = document.getElementById(
      "active-transactions-count"
    );
    countUp(clientCount, 0, heroSections[currentSlide].clientCount, 2000); // Count up to the current client count
    countUp(
      activeTransactionsCount,
      0,
      heroSections[currentSlide].activeTransactionsCount,
      2000
    ); // Count up to the current active transactions count
  };

  return (
    <div className="relative">
      <button
        onClick={prevSlide}
        className="absolute left-0 p-2 ml-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
      >
        <MdArrowBackIos />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 p-2 mr-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
      >
        <MdArrowForwardIos />
      </button>

      <header
        className={`text-[#111217] min-h-screen  bg-contain bg-right bg-no-repeat ${heroSections[currentSlide].backgroundClass}`}
        // style={{ backgroundColor: heroSections[currentSlide].backgroundColor }}
      >
        <Navbar />

        {/* Hero Section */}
        <div className="grid grid-cols-1 gap-8 px-4 py-8 md:py-16 md:px-16 md:grid-cols-12">
          {/* Left side */}
          <div className="md:col-span-7">
            <h1 className="mb-4 text-xl font-bold lg:text-6xl">
              {heroSections[currentSlide].title}
            </h1>
            <p className="mb-8">{heroSections[currentSlide].content}</p>
            <div className="mb-4">
              <Link to={heroSections[currentSlide].buttonLink}>
                <button className="px-6 py-3 rounded-md mr-4 bg-[#ebc132] hover:bg-yellow-400 transition-transform duration-300 transform hover:scale-105">
                  {heroSections[currentSlide].buttonText}
                </button>
              </Link>
              <Link
                to="/connect"
                className="hidden px-6 py-3 underline md:inline-block"
              >
                Contact us
              </Link>
            </div>
          </div>
          {/* Right side */}
          <div className="grid gap-8 md:col-span-5">
            {/* Counting */}
            <div className="flex md:items-center">
              <span className="h-full border-l border-[#111217] mr-4"></span>
              <div>
                <div className="text-2xl font-bold">
                  <span id="client-count">0</span>
                  <span>K+</span>
                </div>
                <p className="text-xs">Client success already</p>
              </div>
            </div>
            <div className="flex items-center">
              <span className="h-full border-l border-[#111217] mr-4"></span>
              <div>
                <div className="text-2xl font-bold">
                  <span id="active-transactions-count">0</span>
                  <span>K+</span>
                </div>
                <p className="text-xs">Active business transactions daily</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeroSection;
