import UssdProduct from "../Components/USSD";
import Zippyworld from "../Components/zippyCard";
import Marketplace from "../Components/marketplace";
import Bag from "../Components/bag";
import Pos1 from "../Components/pos1";
import Pos2 from "../Components/pos2";
import Pos3 from "../Components/pos3";
import pic1 from "../Images/pension.png";
import pic2 from "../Images/insurance.jpg";
import pic3 from "../Images/invest.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
import { useAnimation } from "framer-motion";
import Navbar from "../Components/navBar";

const items = [
  <Zippyworld />,
  <Marketplace />,
  <Bag />,
  <Pos1 />,
  <Pos2 />,
  <Pos3 />,
];

const Products = () => {
  document.title = "Products";

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const drop = useAnimation();

  // drop from top animation
  useEffect(() => {
    if (inView) {
      drop.start({
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          duration: 0.5,
          stiffness: 120,
        },
      });
    }
    if (!inView) {
      drop.start({
        opacity: 0,
        y: -80,
      });
    }
    console.log("useEffect hook, inView = ", inView);
  }, [inView]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 2) % items.length);
    }, 5000); // Change slides every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="px-4 mt-10 mb-20 md:px-16">
        <div className="grid justify-items-center">
          <motion.p
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
            className="w-full md:w-1/2 text-center text-[20px] font-bold bg-clip-text mb-5"
          >
            Our products are tailored towards our customer needs to drive
            business productivity.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
          >
            {items[currentIndex]}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, stiffness: 120 }}
          >
            {items[(currentIndex + 1) % items.length]}
          </motion.div>
        </div>

        <div ref={ref} className="rounded-md bg-mesh">
          <div className="grid items-start grid-cols-1 gap-8 px-8 py-16 mt-5 overflow-hidden rounded-md md:grid-cols-3 md:gap-5 bg-white/60">
            <motion.div animate={drop}>
              <UssdProduct
                pic={pic1}
                title={"USSD Pension"}
                description={
                  "CPS and MPS customers of ARM can now interact with their pension account using *597#"
                }
              />
            </motion.div>

            <motion.div animate={drop}>
              <UssdProduct
                pic={pic2}
                title={"USSD Insurance"}
                description={
                  "Nigerians can now buy general insurance such as third-party Insurance, comprehensive Insurance, and family insurance such as travel insurance, savings and so much more with Allianz on *1016# or STI on *1015#"
                }
              />
            </motion.div>

            <motion.div animate={drop}>
              <UssdProduct
                pic={pic3}
                title={"USSD Investment"}
                description={
                  "You can invest with ARM Investment as low as N5,000 on varieties of investment on *597#"
                }
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
