const Marketplace = () => {
  return (
    <div className="overflow-hidden border rounded-md bg-shopping h-96">
      <div className="relative h-full bg-gradient-to-t from-[#127EC8] to-[#127EC880]">
        <div className="absolute bottom-0 grid gap-5 p-8 text-white">
          <span className="font-bold text-center md:text-left">
            Zippy Marketplace
          </span>
          <span className="text-center md:text-left">
            A marketplace app that offers a convenient platform for users to buy
            and sell a wide variety of goods and services. The marketplace app
            connects buyers and sellers from around the world.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
